



























import { Component, Vue } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import { GridOptions, IDatasource } from 'ag-grid-community'
import moment from 'moment'

import { ActivityDataSource } from '@/api/datasource/admin/adminActivity'
import AgGridFactory from '@/factories/agGridFactory'

import InfoModal from '@/components/common/modal/InfoModal.vue'
import SetFilter from '@/components/table/filters/SetFilter'
import { rolesTranslation } from '@/config/types/roles'
import types from '@/config/types/severityTypes'
import RoleRenderer from '@/components/table/RoleRenderer'
import dateFormat from '@/config/default/dateFormat'
import Config from '@/config/default/config'

@Component({
  components: {
    AgGridVue,
    InfoModal,
    SetFilter
  }
})
export default class Activity extends Vue {
  private dataSource: IDatasource = new ActivityDataSource()

  private errorMessage = ''
  private errorType = ''
  private errorContent = ''

  private width = Config.defaultWidth

  private columnDefs = [
    {
      headerName: 'Severity',
      field: 'severity',
      colId: 'severity',
      width: 100,
      suppressSizeToFit: false,
      cellStyle(params: any) {
        const defStyle = {
          'font-weight': '600'
        }
        if (params.value) {
          if (params.value === 'error') {
            return {
              ...defStyle,
              color: '#e55039'
            }
          } else {
            return {
              ...defStyle,
              color: '#4a69bd'
            }
          }
        } else {
          return defStyle
        }
      },
      filter: 'SetFilter',
      filterParams: {
        filterKeys: types.severityTypes
      }
    },
    {
      headerName: 'Дата',
      field: 'date',
      colId: 'date',
      suppressSizeToFit: false,
      width: 150,
      sortable: true,
      valueGetter(params: any) {
        return params.data && params.data.timestamp ? params.data.timestamp : []
      },
      valueFormatter(params: any) {
        return params.value
          ? moment(params.value).format(dateFormat.date.localFormat)
          : ''
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['reset']
      }
    },
    {
      headerName: 'Время',
      field: 'time',
      colId: 'time',
      suppressSizeToFit: false,
      width: 150,
      sortable: true,
      filter: false,
      valueGetter(params: any) {
        return params.data && params.data.timestamp ? params.data.timestamp : []
      },
      valueFormatter(params: any) {
        return params.value
          ? moment(params.value).format(dateFormat.date.time)
          : ''
      }
    },
    {
      headerName: 'Пользователь',
      field: 'user',
      colId: 'user',
      width: 150,
      filterParams: {
        buttons: ['reset']
      },
      valueFormatter(params: any) {
        if (params.data?.fakeUser) {
          return `${params.data.fakeUser.initials} (${params.value?.initials ??
            ''})`
        }
        return params.value?.initials ?? ''
      }
    },
    {
      headerName: 'Роли',
      field: 'roles',
      colId: 'roles',
      valueGetter(params: any) {
        return params.data && params.data.user ? params.data.user.roles : []
      },
      cellRenderer: RoleRenderer,
      filter: 'SetFilter',
      filterParams: {
        filterKeys: Object.keys(rolesTranslation),
        keyFormatter(key: string) {
          return rolesTranslation[key]
        }
      }
    },
    {
      headerName: 'Страница',
      field: 'page',
      colId: 'page',
      sortable: true,
      filterParams: {
        buttons: ['reset']
      }
    },
    {
      headerName: 'Сообщение',
      field: 'message',
      colId: 'message',
      filter: false
    },
    {
      headerName: 'Ошибка',
      field: 'error',
      colId: 'error',
      sortable: true,
      cellRenderer(params: any) {
        return params.value ? `<div>${params.value.type}</div>` : ''
      },
      filter: false
    }
  ]

  private gridOptions = {
    ...AgGridFactory.getDefaultGridOptions(),
    rowModelType: 'infinite',
    enableCellChangeFlash: true,
    cacheBlockSize: 100,
    rowBuffer: 100,
    pagination: true,
    paginationPageSize: 100,
    onCellClicked: this.onCellClicked,
    frameworkComponents: {
      SetFilter
    }
  }

  private onCellClicked(params: any) {
    if (params.data.error && params.colDef.field === 'error') {
      this.errorMessage = params.data.error.message
      this.errorType = params.data.error.type
      this.errorContent = params.data.error.stack
      this.$bvModal.show('activityErrorModal')
    }
  }

  private reload() {
    ;(this.gridOptions as GridOptions).api!.purgeInfiniteCache()
  }

  private created() {
    this.width = window?.innerWidth ?? Config.defaultWidth
  }
}
