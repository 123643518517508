import DataSource from '@/api/datasource/datasource'
import { IGetRowsParams } from 'ag-grid-community'
import AdminAPI from '@/api/requests/admin'

export class ActivityDataSource extends DataSource {
  getCastCols() {
    return {
      user: 'user.surname'
    }
  }

  getActivitySort(params: IGetRowsParams) {
    return this.castSortModel(
      params.sortModel.map((sm: any) => {
        if (sm.colId === 'date' || sm.colId === 'time') {
          return {
            ...sm,
            colId: 'timestamp'
          }
        }

        return sm
      })
    )
  }

  getActivityFilter(params: IGetRowsParams) {
    const filter = this.castFilterModel(
      params.filterModel.date
        ? {
            ...params.filterModel,
            timestamp: params.filterModel.date,
            date: null
          }
        : params.filterModel
    )
    if (filter.roles) {
      ;(filter['user.roles'] as any) = {
        filterType: 'array',
        array: (filter.roles as any).filter
      }

      delete filter.roles
    }
    if (filter.severity) {
      ;(filter.severity as any) = (filter.severity as any).filter
    }
    if (filter.date === null) {
      delete filter.date
    }
    return filter
  }

  getRows(params: IGetRowsParams) {
    const limit = params.endRow - params.startRow
    const offset = params.startRow
    const sort = this.getActivitySort(params)
    const filter = this.getActivityFilter(params)

    AdminAPI.getActivity(limit, offset, sort, { filter }).then(resp => {
      params.successCallback(resp.data.logs, resp.data.count)
    })
  }
}
